import React from "react"
import "./index.scss"
import portraitImg from "../../images/portraits/portrait.jpg"

class AboutBlock extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="about">
        <section className="about__description">
          <h3>Eine Verbindung zwischen 2 Kulturen.</h3>
          <p>
            Unser Konzept ist darauf ausgelegt, die orientalische Küche und ihre
            reiche Geschichte in Österreich kulturell bekannt zu machen.
          </p>
          <p></p>
        </section>
        <section className="about__portrait">
          {/* <img src={portraitImg} /> */}
          <div className="about__portrait__signature">
            <p>Ferhat Ipek</p>
          </div>
        </section>
      </section>
    )
  }
}

export default AboutBlock
